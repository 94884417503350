<template>
  <div>
    <!-- BOC:[header] -->
    <v-row class="mb-3">
      <v-col class="text-right">
        <v-btn
        class="mr-3"
          color="primary"
          large
          :to="{
            name: 'PageModeratorInstitutionSponsorSponsorshipAdd',
            params: { parentId: parent.id },
          }"
        >
          + New Sponsorship
        </v-btn>
      </v-col>
    </v-row>
    <!-- EOC -->
        <!-- BOC:[table] -->
        <BreadBrowseTable
          role="Moderator"
          :model="model"
          :url="`${$api.servers.sso}/api/v1/en/moderator/sponsor/${parent.id}/sponsorship`"
          :isSearchable="true"
          :parent="parent"
          :modelParent="modelParent"
        ></BreadBrowseTable>
        <!-- EOC -->
  </div>
</template>

<script>
//BOC:[model]
import modelParent from "@/models/items/institutionSponsor";
import model from "@/models/items/sponsorSponsorship";
//EOC
//BOC:[table]
import BreadBrowseTable from "@/components/Bread/BreadBrowseTable";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //BOC:[table]
    BreadBrowseTable,
    //EOC
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  props: ["role", "parent"],
  data: () => ({
    //BOC:[model]
    modelParent: modelParent,
    model: model,
    //EOC
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>